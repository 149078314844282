import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkFlowRoutingModule } from './work-force-routing.module';
import { ListComponent } from './list/list.component';
import { ViewComponent } from './view/view.component';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatRadioModule } from '@angular/material/radio';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

@NgModule({
  declarations: [
    ListComponent,
    ViewComponent,
    IndexComponent
  ],
  imports: [
    CommonModule,
    WorkFlowRoutingModule,
    SharedModule,
    NgxPermissionsModule,
    MaterialmodulesModule,
    NgbModule,
    MatRadioModule,
    PowerBIEmbedModule
  ]
})
export class WorkFlowModule { }
