import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { WorkForceService } from "src/app/shared/services/work-force.service";
import { NgxSpinnerService } from "ngx-spinner";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { DatePipe } from "@angular/common";
import { FilterService } from "src/app/shared/services/filter.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { PowerBiService } from "../../../shared/services/power-bi.service";
import {
  IReportEmbedConfiguration,
  models,
  Page,
  Report,
  service,
  Embed,
} from "powerbi-client";
import { Subscription } from "rxjs";
import { el } from "date-fns/locale";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  providers: [DatePipe],
  // encapsulation:ViewEncapsulation.None
})
export class ListComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  activeTab: any = 0;
  firstTimeLoad: boolean = true;
  allResourceList: any;
  allAttritionList: any;
  allKRRList: any;
  filterObj: any;
  filterString: any = "";
  workForceResourceForm: FormGroup | any;
  editingResourceId: any;
  selectedTab: any;
  pagename: string = "Emerging Trends in Distributed Agile Delivery";
  loading: any = false;

  reportModel: IReportEmbedConfiguration = {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };

  locationBenchmarkModel = this.reportModel;
  krrTTModel = this.reportModel;
  dataAccessModel = this.reportModel;
  costRateModel = this.reportModel;
  experienceModel = this.reportModel;
  reportClass = "report-container";
  browseAIData: any = [];

  accordionItemsLeft = [
    {
      name: "Location Spread",
      id: "location_spread",
      status: "active",
      tab: 1,
      group_id: "",
      report_id: "",
      report_name: "",
    },
    {
      name: "Personas/Profile Analysis",
      id: "personas_profile_analysis",
      status: "inactive",
      tab: 2,
      group_id: "",
      report_id: "",
      report_name: "",
    },
    {
      name: "Cost/Rate Analysis",
      id: "cost_rate_analysis",
      status: "inactive",
      tab: 4,
      group_id: "",
      report_id: "",
      report_name: "",
    },
    {
      name: "Data Access Analysis",
      id: "data_access_analysis",
      status: "inactive",
      tab: 3,
      group_id: "",
      report_id: "",
      report_name: "",
    },
    {
      name: "Attrition Analysis",
      id: "attrition_analysis",
      status: "inactive",
      tab: 5,
      group_id: "",
      report_id: "",
      report_name: "",
    },
    { name: "Workforce Catalog", status: "inactive", tab: 6 },
  ];

  @ViewChild("showErrorLogTable", { static: false }) showErrorLogTable:
    | TemplateRef<any>
    | any;
  private subscription: Subscription;
  constructor(
    private router: Router,
    private workForceService: WorkForceService,
    private spinner: NgxSpinnerService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private PowerBiService: PowerBiService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {
    this.workForceResourceForm = this.fb.group({
      resource_id: [""],
      resource_name: [""],
      job_category: [""],
      job_title: [""],
      company_name: [""],
      classification: [""],
      country: [""],
      krr_classification: [""],
      krr_risk_mitigation_plan: [""],
      work_permit: [""],
      work_permit_expiry_date: [""],
      work_permit_renewal_plan: [""],
      wp_contingency_plan: [""],
      no_of_resource_having_exception_production_access: ["No"],
      no_of_resource_having_exception_generic_id: ["No"],
      no_of_resource_having_exception_pii_access: [""],
      no_of_resource_having_exception_pci_access: [""],
      no_of_resource_having_exception_phi_access: [""],
      no_of_resource_having_exception_cross_border_data_access: [""],
      sow_model: [""],
      approved_by_dl_manager: [""],
      resignation_date: [""],
      dl_notified_date: [""],
      lwd: [""],
      notice_period: [""],
      replacement_status: [""],
      replacement_start_date: [""],
      replacement_name: [""],
      kt_plan: [""],
    });
  }

  ngOnInit(): void {
    this.selectedTab = {
      name: "Location Spread",
      status: "active",
      tab: 1,
      group_id: "e809bdf0-e864-40d8-876f-040da0f80e34",
      report_id: "6bb56088-4e37-4302-abb5-e5f32048fd92",
      report_name: "ReportSection90cf495fd5dce77d5d5e",
    };
    this.getPowerBIReportsDetails();
    if (this.selectedTab?.tab == 6) {
    }
    // this.loadPowerBIReports();

    // this.filterService.filterSubject.subscribe((res: any) => {
    //   this.CurrentPage=0;
    //   this.EndValue=20;
    //   this.startValue== 0;
    //   this.firstTimeLoad = true;

    //   let awl = false;
    //   this.filterObj = res;
    //   Object.keys(this.filterObj).forEach((element: any) => {
    //     if(this.filterObj[element].array.length > 0){
    //       awl = true;
    //       return;
    //     }
    //   });

    // })

    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        let awl = false;
        this.filterObj = res;
        Object.keys(this.filterObj).forEach((element: any) => {
          if (this.filterObj[element]?.array?.length > 0) {
            awl = true;
            return;
          }
        });
        if (this.selectedTab?.tab == 6) {
          if (Object.keys(this.filterObj).length > 0) {
            awl ? this.getList() : this.getList();
          } else {
            this.getList();
          }
        }
      }
    );
    this.route.queryParamMap.subscribe((params) => {
      const tab = params.get("tab");
      if (tab) {
        this.clickOnTab(tab);
      }
    });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  async getBrowseAIData() {
    this.spinner.show();
    await this.workForceService
      .getBrowseAIData("IBM careers")
      .subscribe((res: any) => {
        this.spinner.hide();
        res.data.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
          this.browseAIData.push(element);
        });
      });
  }

  openNewWindow(url: any) {
    const newWindow = window.open(url, "_blank", "width=600,height=400");
  }

  getList() {
    this.getAllResourceList();
    this.getAllAttritionList();
    this.getKRRList();
  }

  getAllResourceList() {
    this.spinner.show();
    this.allResourceList = [];
    let payload = this.getPayload();
    this.workForceService
      .getResourceList(this.startValue, this.EndValue, payload)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.allResourceList = res;
        }
      });
  }

  getAllAttritionList() {
    this.spinner.show();
    this.allAttritionList = [];
    let payload = this.getPayload();
    this.workForceService
      .getAttritionList(this.startValue, this.EndValue, payload)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.allAttritionList = res;
        }
      });
  }

  getKRRList() {
    this.spinner.show();
    this.allKRRList = [];
    let payload = this.getPayload();
    this.workForceService
      .getKKRList(this.startValue, this.EndValue, payload)
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.allKRRList = res;
        }
      });
  }

  getPayload() {
    let payload: any = {};
    if (this.filterObj?.workforceMonth?.array?.length > 0) {
      let months: any = [];
      this.filterObj?.workforceMonth.array.forEach((element: any) => {
        months.push(element.id);
      });
      payload = {
        month: months,
      };
    } else if (this.filterObj?.workforceJobCategory?.array?.length > 0) {
      let job_category: any = [];
      this.filterObj?.workforceJobCategory.array.forEach((element: any) => {
        job_category.push(element.id);
      });
      payload = {
        job_category: job_category,
      };
    } else if (this.filterObj?.vendor?.array?.length > 0) {
      let vendor: any = [];
      this.filterObj?.vendor.array.forEach((element: any) => {
        vendor.push(element.id);
      });
      payload = {
        vendor: vendor,
      };
    } else if (this.filterObj?.workforceClassification?.array?.length > 0) {
      let classification: any = [];
      this.filterObj?.workforceClassification.array.forEach((element: any) => {
        classification.push(element.id);
      });
      payload = {
        classification: classification,
      };
    } else if (this.filterObj?.workforceCountry?.array?.length > 0) {
      let country: any = [];
      this.filterObj?.workforceCountry.array.forEach((element: any) => {
        country.push(element.id);
      });
      payload = {
        country: country,
      };
    } else if (this.filterObj?.portfolio?.array?.length > 0) {
      let portfolio: any = [];
      this.filterObj?.portfolio.array.forEach((element: any) => {
        portfolio.push(element.id);
      });
      payload = {
        portfolio: portfolio,
      };
    } else if (this.filterObj?.subportfolio?.array?.length > 0) {
      let sub_portfolio: any = [];
      this.filterObj?.subportfolio.array.forEach((element: any) => {
        sub_portfolio.push(element.id);
      });
      payload = {
        sub_portfolio: sub_portfolio,
      };
    } else if (this.filterObj?.program?.array?.length > 0) {
      let program: any = [];
      this.filterObj?.program.array.forEach((element: any) => {
        program.push(element.id);
      });
      payload = {
        program: program,
      };
    }
    return payload;
  }

  changeTab(event: any) {
    console.log("tewer");
    this.activeTab = event.index;
    this.CurrentPage = 0;
    this.EndValue = 20;
    this.startValue == 0;
    if (this.activeTab == 0 && this.allResourceList?.count == 0) {
      this.getAllResourceList();
    } else if (this.activeTab == 1 && this.allKRRList?.count == 0) {
      this.getKRRList();
    } else if (this.activeTab == 2 && this.allAttritionList?.count == 0) {
      this.getAllAttritionList();
    }
  }

  getRecords(isFromAttrition?: boolean) {
    if (this.CurrentPage > 0) {
      this.startValue =
        (parseInt(this.CurrentPage) - 1) * parseInt(this.sortRecords);
    } else {
      this.startValue = 0;
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if (this.selectedTab?.tab == 6) {
      if (!this.firstTimeLoad) {
        if (this.activeTab == 0) {
          this.getAllResourceList();
        } else if (this.activeTab == 1) {
          this.getKRRList();
        } else if (this.activeTab == 2) {
          this.getAllAttritionList();
        }
      }
      this.firstTimeLoad = false;
    }
  }

  editWorkForceById(id: number) {
    this.router.navigate([`workforce/edit/${btoa(id + "")}`]);
  }

  editWorkForceDetails(id: any, type: any, modal: any) {
    this.editingResourceId = id;
    let workForceDetails = "";
    this.spinner.show();
    if (type == "attrition") {
      this.workForceService
        .getWorkForceAttritionDataById(id)
        .subscribe((res: any) => {
          this.spinner.hide();
          this.patchWorkForceValues(res, modal);
        });
    } else if (type == "resource") {
      this.workForceService
        .getWorkForceResourceDataById(id)
        .subscribe((res: any) => {
          this.patchWorkForceValues(res, modal);
          this.spinner.hide();
        });
    } else if (type == "krr") {
      this.workForceService
        .getWorkForceKrrDataById(id)
        .subscribe((res: any) => {
          this.patchWorkForceValues(res, modal);
          this.spinner.hide();
        });
    }
  }

  patchWorkForceValues(res: any, modal: any) {
    this.workForceResourceForm.patchValue(res);
    this.dialog.open(modal, {
      width: "40vw",
      maxHeight: "70vh",
    });
  }
  updateWorkForceDetails() {
    this.spinner.show();
    let data = this.workForceResourceForm.value;
    this.workForceService
      .updateWorkForceDetailsById(data, this.editingResourceId)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.toastrService.success("Resource updated successfully.");

        this.dialog.closeAll();
      });
  }
  cancel() {
    this.dialog.closeAll();
  }

  clickOnTab(tab: any) {
    this.accordionItemsLeft = this.accordionItemsLeft.map((obj: any) => {
      if (obj.tab == tab) {
        this.selectedTab = obj;
        return { ...obj, status: "active" };
      } else {
        return { ...obj, status: "inactive" };
      }
    });
    if (tab == 6) {
      if (Object.keys(this.filterObj).length > 0) {
        this.getList();
      } else {
        this.getList();
      }
    }
  }

  loadPowerBIReports() {
    let basicFilter = "";
    this.PowerBiService.getTokenForBI().subscribe((res: any) => {
      this.accordionItemsLeft.forEach((ele: any) => {
        if (ele.report_id) {
          this.PowerBiService.getBIEmbedURL(
            res.access_token,
            ele.report_id
          ).subscribe((reportData: any) => {
            this.PowerBiService.getBIEmbedToken(
              res.access_token,
              ele.report_id,
              ele.group_id
            ).subscribe((tokenData: any) => {
              if (ele.tab == 1)
                this.locationBenchmarkModel = this.loadPowerBIReportGraph(
                  reportData,
                  tokenData,
                  basicFilter,
                  ele.report_name
                );
              if (ele.tab == 2)
                this.krrTTModel = this.loadPowerBIReportGraph(
                  reportData,
                  tokenData,
                  basicFilter,
                  ele.report_name
                );
              if (ele.tab == 3)
                this.dataAccessModel = this.loadPowerBIReportGraph(
                  reportData,
                  tokenData,
                  basicFilter,
                  ele.report_name
                );
              if (ele.tab == 4)
                this.costRateModel = this.loadPowerBIReportGraph(
                  reportData,
                  tokenData,
                  basicFilter,
                  ele.report_name
                );
              if (ele.tab == 5)
                this.experienceModel = this.loadPowerBIReportGraph(
                  reportData,
                  tokenData,
                  basicFilter,
                  ele.report_name
                );
            });
          });
        }
      });
    });
  }

  loadPowerBIReportGraph(
    reportData: any,
    tokenData: any,
    basicFilter: any,
    pageName: any
  ) {
    return {
      type: "report",
      id: reportData.id,
      embedUrl: reportData.embedUrl,
      accessToken: tokenData.token,
      tokenType: models.TokenType.Embed,
      pageName: pageName,
      // filters: [basicFilter],
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
        zoomLevel: 0.6,
        background: models.BackgroundType.Transparent,
      },
    };
  }

  clickToDownload() {
    let url = "",
      name = "";
    if (this.activeTab == 0) {
      url = "delivery/get-resource-data/";
      name = "Resources";
    } else if (this.activeTab == 1) {
      url = "delivery/get-krr-data/";
      name = "KRR/TT";
    } else if (this.activeTab == 2) {
      url = "delivery/get-attrition-data/";
      name = "Attrition";
    }

    let payload: any = {};
    if (this.filterObj?.workforceMonth?.array?.length > 0) {
      let months: any = [];
      this.filterObj?.workforceMonth.array.forEach((element: any) => {
        months.push(element.id);
      });
      payload = {
        month: months,
      };
    } else if (this.filterObj?.workforceJobCategory?.array?.length > 0) {
      let job_category: any = [];
      this.filterObj?.workforceJobCategory.array.forEach((element: any) => {
        job_category.push(element.id);
      });
      payload = {
        job_category: job_category,
      };
    } else if (this.filterObj?.vendor?.array?.length > 0) {
      let vendor: any = [];
      this.filterObj?.vendor.array.forEach((element: any) => {
        vendor.push(element.id);
      });
      payload = {
        vendor: vendor,
      };
    } else if (this.filterObj?.workforceClassification?.array?.length > 0) {
      let classification: any = [];
      this.filterObj?.workforceClassification.array.forEach((element: any) => {
        classification.push(element.id);
      });
      payload = {
        classification: classification,
      };
    } else if (this.filterObj?.workforceCountry?.array?.length > 0) {
      let country: any = [];
      this.filterObj?.workforceCountry.array.forEach((element: any) => {
        country.push(element.id);
      });
      payload = {
        country: country,
      };
    } else if (this.filterObj?.portfolio?.array?.length > 0) {
      let portfolio: any = [];
      this.filterObj?.portfolio.array.forEach((element: any) => {
        portfolio.push(element.id);
      });
      payload = {
        portfolio: portfolio,
      };
    } else if (this.filterObj?.subportfolio?.array?.length > 0) {
      let sub_portfolio: any = [];
      this.filterObj?.subportfolio.array.forEach((element: any) => {
        sub_portfolio.push(element.id);
      });
      payload = {
        sub_portfolio: sub_portfolio,
      };
    } else if (this.filterObj?.program?.array?.length > 0) {
      let program: any = [];
      this.filterObj?.program.array.forEach((element: any) => {
        program.push(element.id);
      });
      payload = {
        program: program,
      };
    }
    payload.response_type = "csv";
    this.spinner.show();
    this.workForceService
      .downloadPeopleInsights(url, payload)
      .subscribe((res: any) => {
        const blobUrl = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = name + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
        this.spinner.hide();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadResourceContentType(event: any, index: any, resource: any) {
    let payload = {
      contract_type: "T&M",
      contract_id: null,
      contract_name: null,
    };
    if (event == "T&M") {
      this.patchResourceContract(resource.id, payload);
    } else {
      this.getContractList(index, resource);
    }
  }

  loadResourceContract(event: any, index: any, resource: any) {
    console.log(event);
    let payload = {
      contract_type: "SOW",
      contract_id: event.contract_id,
      contract_name: event.contract_name,
    };
    this.allResourceList.data[index].contract_name = event.contract_name;
    this.patchResourceContract(resource.id, payload);
  }

  patchResourceContract(id: any, payload: any) {
    this.spinner.show();
    this.workForceService
      .updateResourceContract(id, payload)
      .subscribe((res: any) => {
        this.spinner.hide();
      });
  }

  getContractList(index: any, resource: any) {
    this.spinner.show();
    let params = {
      project_id: resource?.project,
      portfolio_name: resource?.portfolio_name,
      subportfolio_name: resource?.cost_center_name,
      program_name: resource?.resource_pool_name,
      vendor: resource?.company_name,
    };
    this.workForceService
      .getProjectVendorContractList(params)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.allResourceList.data[index].contractList = res;
      });
  }

  getPowerBIReportsDetails() {
    this.PowerBiService.getPowerBIReportDetails("people_insight").subscribe(
      (res: any) => {
        this.accordionItemsLeft.forEach((accord: any, index: any) => {
          let biresult = res.filter((item: any) => item.section === accord.id);
          if (biresult.length > 0) {
            this.accordionItemsLeft[index].group_id = biresult[0].group;
            this.accordionItemsLeft[index].report_id = biresult[0].report_id;
            this.accordionItemsLeft[index].report_name =
              biresult[0].report_name;
          }
        });
        this.loadPowerBIReports();
      }
    );
  }
}
