<div>
  <app-filter-card [pagetype]="'people-insights'"></app-filter-card>
</div>
<div class="px-0 py-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <div style="flex-grow: 1">
        <div class="vsScroll">
          <div style="display: flex">
            <div
              *ngFor="let item of accordionItemsLeft"
              class="insight-button"
              [ngClass]="{ 'insight-active': item.status === 'active' }"
              (click)="clickOnTab(item.tab)"
            >
              <div>{{ item.name }}</div>
            </div>
            <!-- <mat-icon  *ngIf="selectedTab?.tab == 6" aria-hidden="false" aria-label="material-icons-filled" (click)="clickToDownload()"
                    class="cursor-pointer" style="color:#ED0000;font-size:32px;margin-top: 16px;" >cloud_download
                </mat-icon> -->
          </div>
        </div>
      </div>
      <br />
      <!-- <div class="accordion d-flex" id="accordionOverviewLeft">
            <div class="w-100 pr-4">
                <div *ngFor="let item of accordionItemsLeft;index as i;" class="accordion-item">
                    <h2 class="accordion-header" [id]="'headingOneCenterLeft'+i" >
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" attr.data-bs-target="#collapseOneCenterLeft{{i}}" aria-expanded="true" aria-controls="collapseOneCenterLeft{{i}}" style="font-size: 13px">
                        {{item}}
                        </button>
                    </h2>
                    <div [id]="'collapseOneCenterLeft'+i" class="accordion-collapse collapse" [aria-labelledby]="'headingOneCenterLeft'+i" data-bs-parent="#accordionOverviewLeft">
                        <div class="accordion-body">
                            <div class="comingSoonCenterBlock text-center">
                                <div><img src="assets/images/analytics_bg.png"></div>
                                <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <div class="w-50">
                <div *ngFor="let item of accordionItemsRight;index as i;" class="accordion-item">
                    <h2 class="accordion-header" [id]="'headingOneCenterRight'+i" >
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" attr.data-bs-target="#collapseOneCenterRight{{i}}" aria-expanded="true" aria-controls="collapseOneCenterRight{{i}}" style="font-size: 13px">
                        {{item}}
                        </button>
                    </h2>
                    <div [id]="'collapseOneCenterRight'+i" class="accordion-collapse collapse" [aria-labelledby]="'headingOneCenterRight'+i" data-bs-parent="#accordionOverviewRight">
                        <div class="accordion-body">
                            <div class="comingSoonCenterBlock text-center">
                                <div><img src="assets/images/analytics_bg.png"></div>
                                <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
        <br>-->
      <div *ngIf="selectedTab?.tab == 1">
        <powerbi-report
          [embedConfig]="locationBenchmarkModel"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>
      <div *ngIf="selectedTab?.tab == 2">
        <powerbi-report
          [embedConfig]="krrTTModel"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>
      <div *ngIf="selectedTab?.tab == 3">
        <powerbi-report
          [embedConfig]="dataAccessModel"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>
      <div *ngIf="selectedTab?.tab == 4">
        <powerbi-report
          [embedConfig]="costRateModel"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>
      <div *ngIf="selectedTab?.tab == 5">
        <powerbi-report
          [embedConfig]="experienceModel"
          [cssClassName]="reportClass"
        ></powerbi-report>
      </div>

      <div *ngIf="selectedTab?.tab == 6">
        <mat-tab-group
          mat-align-tabs="start"
          class="head"
          (selectedTabChange)="changeTab($event)"
        >
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="d-flex align-items-center">
                <span
                  >{{ staticText?.delivery.resources.table }} ({{
                    allResourceList?.count
                  }})</span
                >&nbsp;&nbsp;
                <mat-icon
                  *ngIf="selectedTab?.tab == 6 && activeTab == 0"
                  aria-hidden="false"
                  aria-label="material-icons-filled"
                  (click)="clickToDownload()"
                  class="cursor-pointer"
                  style="
                    overflow: visible !important;
                    color: #ffffff;
                    font-size: 26px;
                  "
                  >cloud_download
                </mat-icon>
              </div>
            </ng-template>

            <div *ngIf="activeTab == 0">
              <!-- <div class="text-right m-1">
                        <button mat-button (click)="clickToDownload('delivery/get-resource-data/','Resources')"
                            class="bg-primary text-white" mat-button mat-flat-button>
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled "
                                class="cursor-pointer fs-5">cloud_download
                            </mat-icon>
                            Download
                        </button>
                    </div> -->
              <div
                class="custom-table fixed-table-header"
                style="max-height: calc(100vh - 350px)"
              >
                <table
                  class="table table-bordered text-left"
                  style="min-height: 100px"
                >
                  <thead>
                    <tr>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">#</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.resource_id
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.resrc_name
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.worker_cat
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.job_title
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >OU/GDP</span
                        >
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >Contract Type</span
                        >
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >Contract Name</span
                        >
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >Action</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="cursor-pointer"
                    *ngIf="allResourceList?.data?.length"
                  >
                    <tr
                      *ngFor="
                        let tableRecords of allResourceList?.data;
                        index as tableIndex
                      "
                      class="f-13"
                    >
                      <td scope="row">{{ startValue + tableIndex + 1 }}</td>
                      <td class="color-blue">
                        {{ tableRecords?.resource_id || "-" }}
                      </td>
                      <td>{{ tableRecords?.resource_name || "-" }}</td>
                      <td>{{ tableRecords?.job_category || "-" }}</td>
                      <td>{{ tableRecords?.job_title || "-" }}</td>
                      <td>{{ tableRecords?.company_name || "-" }}</td>
                      <td style="width: 25px">
                        <span
                          class="d-flex fs-13"
                          *ngIf="tableRecords?.contract_type"
                        >
                          {{ tableRecords?.contract_type }}
                        </span>
                        <span
                          *ngIf="
                            tableRecords?.contract_type != 'T&M' &&
                            !tableRecords?.contract_name
                          "
                        >
                          <mat-form-field
                            appearance="outline"
                            style="width: 65px; z-index: 0"
                          >
                            <mat-select
                              (ngModelChange)="
                                loadResourceContentType(
                                  $event,
                                  tableIndex,
                                  tableRecords
                                )
                              "
                              [(ngModel)]="tableRecords.contract_type"
                            >
                              <mat-option value="SOW">SOW</mat-option>
                              <mat-option value="T&M">T&M</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </span>
                      </td>
                      <td>
                        <span
                          class="d-flex fs-13"
                          *ngIf="tableRecords?.contract_name"
                        >
                          {{ tableRecords?.contract_name || "-" }}
                        </span>
                        <span
                          *ngIf="
                            !tableRecords?.contract_name &&
                            tableRecords.contract_type == 'SOW'
                          "
                        >
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-select
                              (ngModelChange)="
                                loadResourceContract(
                                  $event,
                                  tableIndex,
                                  tableRecords
                                )
                              "
                              [(ngModel)]="tableRecords.contract_object"
                            >
                              <mat-option
                                *ngFor="
                                  let contract of tableRecords?.contractList
                                "
                                [value]="contract"
                                >{{ contract?.contract_name }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </span>
                      </td>
                      <td>
                        <div class="align-items-center">
                          <img
                            src="./assets/images/pages/workforce/edit.svg"
                            alt="edit"
                            (click)="
                              editWorkForceDetails(
                                tableRecords?.id,
                                'resource',
                                editWorkforcePopUp
                              )
                            "
                          />
                          <!-- <img src="./assets/images/comment-delete.svg" alt="delete"
                                                *ngxPermissionsOnly="['delivery.delete_workforcedashboard']"
                                                (click)="deletePopUp(tableRecords?.id)"> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <ng-container *ngIf="!allResourceList?.data?.length">
                    <div
                      class="noRecords d-flex justify-content-center text-center"
                    >
                      <span>
                        {{ staticText.common.no_records_found }}
                      </span>
                    </div>
                  </ng-container>
                </table>
              </div>
              <div
                class="d-flex align-items-center justify-content-between"
                *ngIf="allResourceList?.count > 0"
              >
                <div class="p-1">
                  <div
                    class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
                  >
                    <span class="p-1">Displaying</span>
                    <select
                      class="form-select form-select-sm table-sort-filter"
                      aria-label="Default select example"
                      [(ngModel)]="sortRecords"
                      (ngModelChange)="getRecords()"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                    <span class="p-1">{{ allResourceList?.count }}</span>
                    <span class="p-1">records</span>
                  </div>
                </div>
                <div class="p-1">
                  <ngb-pagination
                    [pageSize]="sortRecords"
                    [collectionSize]="allResourceList?.count"
                    [maxSize]="2"
                    [rotate]="true"
                    [(page)]="CurrentPage"
                    [boundaryLinks]="true"
                    (pageChange)="getRecords()"
                  >
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="d-flex align-items-center">
                <span
                  >{{ staticText?.delivery.resources.kkr }} ({{
                    allKRRList?.count
                  }})</span
                >&nbsp;&nbsp;
                <mat-icon
                  *ngIf="selectedTab?.tab == 6 && activeTab == 1"
                  aria-hidden="false"
                  aria-label="material-icons-filled"
                  (click)="clickToDownload()"
                  class="cursor-pointer"
                  style="
                    overflow: visible !important;
                    color: #ffffff;
                    font-size: 26px;
                  "
                  >cloud_download
                </mat-icon>
              </div>
            </ng-template>
            <div *ngIf="activeTab == 1">
              <!-- <div class="text-right m-1">
                        <button mat-button (click)="clickToDownload('delivery/get-krr-data/','KRR/TT')"
                            class="bg-primary text-white" mat-button mat-flat-button>
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled "
                                class="cursor-pointer fs-5">cloud_download
                            </mat-icon>
                            Download
                        </button>
                    </div> -->
              <div
                class="custom-table fixed-table-header"
                style="max-height: calc(100vh - 350px)"
              >
                <table
                  class="table table-bordered text-left"
                  style="min-height: 100px"
                >
                  <thead>
                    <tr>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">#</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.resource_id
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.resrc_name
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.worker_cat
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.job_title
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >OU/GDP</span
                        >
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >Classification</span
                        >
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.country
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >Action</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="cursor-pointer"
                    *ngIf="allKRRList?.data?.length"
                  >
                    <tr
                      *ngFor="
                        let tableRecords of allKRRList?.data;
                        index as tableIndex
                      "
                    >
                      <td scope="row">{{ tableIndex + 1 }}</td>
                      <td class="color-blue">
                        {{ tableRecords?.resource_id || "-" }}
                      </td>
                      <td>{{ tableRecords?.resource_name || "-" }}</td>
                      <td>{{ tableRecords?.job_category || "-" }}</td>
                      <td>{{ tableRecords?.job_title || "-" }}</td>
                      <td>{{ tableRecords?.company_name || "-" }}</td>
                      <td>{{ tableRecords?.classification || "-" }}</td>
                      <td>{{ tableRecords?.country || "-" }}</td>
                      <td>
                        <div class="align-items-center">
                          <img
                            src="./assets/images/pages/workforce/edit.svg"
                            alt="edit"
                            (click)="
                              editWorkForceDetails(
                                tableRecords?.id,
                                'krr',
                                editWorkforcePopUp
                              )
                            "
                          />
                          <!-- <img src="./assets/images/comment-delete.svg" alt="delete"
                                                *ngxPermissionsOnly="['delivery.delete_workforcedashboard']"
                                                (click)="deletePopUp(tableRecords?.id)"> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <ng-container *ngIf="!allKRRList?.data?.length">
                    <div
                      class="noRecords d-flex justify-content-center text-center"
                    >
                      <span>
                        {{ staticText.common.no_records_found }}
                      </span>
                    </div>
                  </ng-container>
                </table>
              </div>
              <div
                class="d-flex align-items-center justify-content-between"
                *ngIf="allKRRList?.count > 0"
              >
                <div class="p-1">
                  <div
                    class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
                  >
                    <span class="p-1">Displaying</span>
                    <select
                      class="form-select form-select-sm table-sort-filter"
                      aria-label="Default select example"
                      [(ngModel)]="sortRecords"
                      (ngModelChange)="getRecords(true)"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                    <span class="p-1">{{ allKRRList?.count }}</span>
                    <span class="p-1">records</span>
                  </div>
                </div>
                <div class="p-1">
                  <ngb-pagination
                    [pageSize]="sortRecords"
                    [collectionSize]="allKRRList?.count"
                    [maxSize]="2"
                    [rotate]="true"
                    [(page)]="CurrentPage"
                    [boundaryLinks]="true"
                    (pageChange)="getRecords(true)"
                  >
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="d-flex align-items-center">
                <span
                  >{{ staticText?.delivery.resources.attrition }} ({{
                    allAttritionList?.count
                  }})</span
                >&nbsp;&nbsp;
                <mat-icon
                  *ngIf="selectedTab?.tab == 6 && activeTab == 2"
                  aria-hidden="false"
                  aria-label="material-icons-filled"
                  (click)="clickToDownload()"
                  class="cursor-pointer"
                  style="
                    overflow: visible !important;
                    color: #ffffff;
                    font-size: 26px;
                  "
                  >cloud_download
                </mat-icon>
              </div>
            </ng-template>
            <div *ngIf="activeTab == 2">
              <!-- <div class="text-right m-1">
                        <button mat-button (click)="clickToDownload('delivery/get-attrition-data/','Attrition')"
                            class="bg-primary text-white" mat-button mat-flat-button>
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled "
                                class="cursor-pointer fs-5">cloud_download
                            </mat-icon>
                            Download
                        </button>
                    </div> -->
              <div
                class="custom-table fixed-table-header"
                style="max-height: calc(100vh - 350px)"
              >
                <table
                  class="table table-bordered text-left"
                  style="min-height: 100px"
                >
                  <thead>
                    <tr>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">#</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >{{ staticText.workforce_dashboard.resource_id }}
                        </span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.resrc_name
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center">{{
                          staticText.workforce_dashboard.job_title
                        }}</span>
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >Assignment start date</span
                        >
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >Assignment end date</span
                        >
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >Reason</span
                        >
                      </th>
                      <th
                        class="fs-14 text-capitalize fw-500 text-white table-header border-end border-light"
                        scope="col"
                      >
                        <span class="d-flex justify-content-center"
                          >OU/GDP</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody class="cursor-pointer">
                    <tr
                      *ngFor="
                        let tableRecords of allAttritionList?.data;
                        index as tableIndex
                      "
                    >
                      <td scope="row">{{ startValue + tableIndex + 1 }}</td>
                      <td class="color-blue">
                        {{ tableRecords?.ppr_num || "-" }}
                      </td>
                      <td>{{ tableRecords?.cwr_name || "-" }}</td>
                      <td>{{ tableRecords?.job_title || "-" }}</td>
                      <td>
                        {{
                          tableRecords?.assignment_start_date || "Not Available"
                        }}
                      </td>
                      <td>
                        {{
                          tableRecords?.assignment_end_date || "Not Available"
                        }}
                      </td>
                      <td>
                        {{
                          tableRecords?.end_of_assignment_reason ||
                            "Not Available"
                        }}
                      </td>
                      <td>{{ tableRecords?.vendor || "-" }}</td>
                    </tr>
                  </tbody>
                  <ng-container *ngIf="!allAttritionList?.data?.length">
                    <div class="noRecords text-center">
                      <span>
                        {{ staticText.common.no_records_found }}
                      </span>
                    </div>
                  </ng-container>
                </table>
              </div>
              <div
                class="d-flex align-items-center justify-content-between"
                *ngIf="allAttritionList?.count > 0"
              >
                <div class="p-1">
                  <div
                    class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
                  >
                    <span class="p-1">Displaying</span>
                    <select
                      class="form-select form-select-sm table-sort-filter"
                      aria-label="Default select example"
                      [(ngModel)]="sortRecords"
                      (ngModelChange)="getRecords(true)"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                    <span class="p-1">{{ allAttritionList?.count }}</span>
                    <span class="p-1">records</span>
                  </div>
                </div>
                <div class="p-1">
                  <ngb-pagination
                    [pageSize]="sortRecords"
                    [collectionSize]="allAttritionList?.count"
                    [maxSize]="2"
                    [rotate]="true"
                    [(page)]="CurrentPage"
                    [boundaryLinks]="true"
                    (pageChange)="getRecords(true)"
                  >
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
      <app-gnews></app-gnews>
      <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                        style="font-size: 13px">
                        Curated from Web
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body p-2">
                        <app-loading *ngIf="loading"></app-loading>
                        <app-gnews [display_header]="'false'"></app-gnews>
                    </div>
                </div>
            </div>  -->
    </div>

    <!-- <div class="single-right-pane">
          <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
          <h6>Curated from the Web</h6>
            <div *ngFor="let data of browseAIData">
                <div class="browseai-card" [ngStyle]="{'background': data.color}" >
                    <div class="d-flex justify-content-between">
                        <h6 style="font-size:14px">{{data?.['title']}}</h6>
                        <div class="text-right">
                            
                            <a (click="openNewWindow(data?.['url'])" ><mat-icon class="browseai-card-link" >link</mat-icon></a>
                        </div>
                    </div>
                    <p class="browseai-card-desc">{{data?.['description']}}</p>
                    <p class="browseai-card-details d-flex mt-2">
                        <mat-icon style="font-size:14px;height: 24px;width:24px;">date_range</mat-icon> 
                        <span>{{data?.['published_date'] | date:'yyyy-MMM-dd' }}</span> 
                    </p>
                    
                </div>
            </div>
          
        </div> -->
  </div>
</div>

<ng-template #editWorkforcePopUp>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="'Resource Details'">
      </app-modal-header-common>

      <div
        class="modal-body p-0 p-3 incident-tracker"
        style="overflow-y: auto; height: 50vh"
      >
        <form [formGroup]="workForceResourceForm">
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Resource Id</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="resource_id"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Resource Name</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="resource_name"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Job Category</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="job_category"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Job Title</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="job_title"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>OU/GDP</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="company_name"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Classification</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="classification"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Country</mat-label>
                <input
                  type="text"
                  readonly
                  matInput
                  status="basic"
                  formControlName="country"
                  style="background-color: beige"
                />
              </mat-form-field>
            </div>
          </div>
          <h6>KRR/TT</h6>
          <div class="row">
            <div class="col-lg-6">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>KRR Classification</mat-label>
                <!-- <input matInput type="text" status="basic"  formControlName="Status"> -->
                <mat-select formControlName="krr_classification">
                  <mat-option value="KRR">KRR</mat-option>
                  <mat-option value="TT">TT</mat-option>
                  <mat-option value="All Other">All Other</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>KRR Risk Mitigation Plan</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="krr_risk_mitigation_plan"
                />
              </mat-form-field>
            </div>
          </div>
          <h6>Work Permit</h6>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="work_permit"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit Expiry Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker1"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker1.open()"
                  formControlName="work_permit_expiry_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker1">
                </mat-datepicker-toggle>
                <mat-datepicker #picker1> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit Renewal Plan</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="work_permit_renewal_plan"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit Contingency Plan</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="wp_contingency_plan"
                />
              </mat-form-field>
            </div>
          </div>
          <h6>Data Access</h6>
          <div class="row">
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception Production access</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_production_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt1">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt2" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception Generic ID</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_generic_id"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt3">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt4" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception PII access</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_pii_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt5">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt6" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception PCI access</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_pci_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt5">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt5" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception PHI access</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_phi_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt5">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt5" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <label style="font-size: 12px"
                >Resource having Exception cross border data access (without
                GDPR compliance)</label
              >
              <mat-radio-group
                formControlName="no_of_resource_having_exception_cross_border_data_access"
                class="mx-3"
                style="display: block"
              >
                <mat-radio-button value="Yes" name="opt5">Yes</mat-radio-button>
                <mat-radio-button value="No" name="opt5" class="mx-4"
                  >No</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>SOW Model</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="sow_model"
                />
              </mat-form-field>
            </div>
          </div>
          <h6>Resignation Details</h6>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Approved by DL Manager</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="approved_by_dl_manager"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Work Permit Expiry Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker2.open()"
                  formControlName="resignation_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker2">
                </mat-datepicker-toggle>
                <mat-datepicker #picker2> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>DL notified Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker3"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker3.open()"
                  formControlName="dl_notified_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker3">
                </mat-datepicker-toggle>
                <mat-datepicker #picker3> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Last Working Day</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker11"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker11.open()"
                  formControlName="lwd"
                />
                <mat-datepicker-toggle matSuffix [for]="picker11">
                </mat-datepicker-toggle>
                <mat-datepicker #picker11> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Notice Period</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="notice_period"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Replacement Status </mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="replacement_status"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Replacement Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker4"
                  readonly
                  [ngModelOptions]="{ standalone: true }"
                  fullWidth
                  status="basic"
                  (click)="picker4.open()"
                  formControlName="replacement_start_date"
                />
                <mat-datepicker-toggle matSuffix [for]="picker4">
                </mat-datepicker-toggle>
                <mat-datepicker #picker4> </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>Replacement Name</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="replacement_name"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="col-12 px-2" appearance="outline">
                <mat-label>KT Plan</mat-label>
                <input
                  type="text"
                  matInput
                  status="basic"
                  formControlName="kt_plan"
                />
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="d-flex justify-content-between">
            <button (click)="cancel()" mat-flat-button color="secondary">
              Close
            </button>
            <button
              (click)="updateWorkForceDetails()"
              class="mr-2 ml-2"
              mat-flat-button
              color="primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
